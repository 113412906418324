import React, { useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import axios from 'axios';
import { BsHeart } from 'react-icons/bs';
import { TiArrowSync } from 'react-icons/ti';
import { BiMessageAlt } from 'react-icons/bi';

function Posts() {
    const [posts, setPosts] = useState([]);
    const { user } = useAuth();
    
    useEffect(() => {
        fetchPosts();
    }, []);

    const fetchPosts = async () => {
        axios({method: 'POST', url: 'https://wallet-auth.vtsxcode.xyz/getposts', data: {session: user.nakamaSession}}).then((res) => {setPosts(res.data);});
    }

    return (
        <div className='postsParentContainer'>
            <div className='postsDisplayContainer'>
                { posts.map((post) => { return <PostCard key={post.post_id} {...post}/>}) }
            </div>
        </div>
    );
};

export default Posts;

function PostCard({username, post_content, created, post_game, post_category, post_tags}) {
    let timestamp = new Date(created);
    return (
        <div className='postCard'>
            <div className='postCardContents'>
                <p className='postUsername'>{username}</p>
                <p className='postGameTitle'>{post_game}</p>
                <p className='postContent'>{post_content}</p>
                {post_category ? <p className='postCategory'>Category: {post_category}</p> : <></>}
                {post_tags ? <p className='postTags'>Tags: {post_tags}</p> : <></>}
                <p className='postCreated'>{timestamp.toLocaleDateString('en-US')}</p>
            </div>
            <div className='postButtons'>
                <LikeButton />
                <RepostButton />
                <CommentButton/>
            </div>
        </div>
        
    );
};

function LikeButton() {
    let likeCount = 0;
    return (
        <div className='likeButton'>
            <div className='likeButtonIcon'>
                <BsHeart size={23} />
            </div>
            <p className='interactionCount'>{likeCount}</p>
        </div>
    );
};

function RepostButton() {
    let repostCount = 0;
    return (
        <div className='RepostButton'>
            <div className='repostButtonIcon'>
                <TiArrowSync size={23} />
            </div>
            <p className='interactionCount'>{repostCount}</p>
        </div>
    );
};

function CommentButton() {
    let commentCount = 0;
    return (
        <div className='commentButton'>
            <div className='commentButtonIcon'>
                <BiMessageAlt size={23} />
            </div>
            <p className='interactionCount'>{commentCount}</p>
        </div>
    );
};