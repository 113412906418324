import React, { useState } from 'react';
import { RiMailSendLine } from 'react-icons/ri';
import { BiImageAdd } from 'react-icons/bi';
import axios from 'axios';
import useAuth from "../hooks/useAuth";

function CreatePosts() {
    const { user } = useAuth();
    const [ postContent, setPostContent] = useState({content: '', category: '', tags: '', game: ''});
    const SubmitPost = async (e) => {
        e.preventDefault();
        console.log(postContent);
        axios({method: 'POST', url: 'https://wallet-auth.vtsxcode.xyz/addpost', data: {session: user.nakamaSession, content: postContent }});
    }

    return (
        <div className='createPostContainer'>
            <div className='createPostInputContainer'>
                <div className='dropImageArea'>
                    <BiImageAdd className='dropImageAreaIcon' size={32}/>
                </div>
                <form className='createPostForm' onSubmit={SubmitPost}>
                    <textarea className='postContentInputText' onChange={(e) => {e.target.value=e.target.value.replace(/\n/g, ''); setPostContent({...postContent, content: e.target.value})}}/>
                    <input className='postContentInput' type='text' placeholder='category' onChange={(e) => {e.target.value=e.target.value.replace(/[\n\s]/g, ''); setPostContent({...postContent, category: e.target.value})}}/>
                    <button className='postContentButton' type='submit'><RiMailSendLine size={32}/></button>
                </form>
                <div className='additionalPostData'>
                    <input className='postContentInput' type='text' placeholder='Tags' onChange={(e) => {e.target.value=e.target.value.replace(/[\n\s]/g, ''); setPostContent({...postContent, tags: e.target.value})}}/>
                    <input className='postContentInput' type='text' placeholder='Game' onChange={(e) => {e.target.value=e.target.value.replace(/[\n\s]/g, ''); setPostContent({...postContent, game: e.target.value})}}/>
                </div>
            </div>
        </div>
    );
}

export default CreatePosts;