import React from 'react';
import CreatePosts from '../components/CreatePosts';
import Posts from '../components/Posts';

function PostsPage() {
    return (
        <>
            <CreatePosts />
            <Posts />
        </>
    );
}

export default PostsPage;