function Feed({children}) {
  return (
    <div className="newsFeed">
      <div className="">
        <h2>NEWS FEED</h2>
        {children}
      </div>
    </div>
  );
}

export default Feed;
